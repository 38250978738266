<template>
  <div class="categories">
    <h1 class="font-semibold text-xl mb-5 flex justify-between items-center">
      <div>
        Категории ({{ filters.total }})
        <i v-if="loading" class="el-icon-loading"></i>
      </div>
      <div class="flex items-center w-full justify-end">
        <el-input
          v-model="filters.query"
          @keyup.native.enter="handleFiltersChange"
          @clear="handleFiltersChange"
          clearable
          size="medium"
          placeholder="Поиск"
          class="mr-2"
          style="width: 220px"
        ></el-input>

        <el-button
          size="medium"
          plain
          type="primary"
          icon="el-icon-plus"
          :disabled="loading"
          @click="editCategory({ id: 'new' })"
          >Добавить</el-button
        >
      </div>
    </h1>

    <el-table :data="categories" border size="small" @row-click="editCategory">
      <el-table-column
        type="index"
        :index="indexMethod"
        width="50"
        label="N"
        align="center"
      ></el-table-column>
      <el-table-column prop="name" label="Название"></el-table-column>
      <el-table-column prop="slug" label="Slug"></el-table-column>
      <el-table-column prop="description" label="Описание"></el-table-column>
    </el-table>

    <el-pagination
      class="text-center my-5"
      background
      :disabled="loading"
      layout="prev, pager, next"
      :total="filters.total"
      :page-size="filters.limit"
      :current-page.sync="filters.page"
      @current-change="() => handleFiltersChange('page')"
    >
    </el-pagination>

    <el-dialog
      :title="isNewCategory ? 'Создание категории' : 'Редактирование категории'"
      :visible.sync="dialogVisible"
      width="500px"
      @close="handleDialogClose"
    >
      <el-form
        ref="categoryForm"
        :model="categoryForm"
        :rules="rules"
        label-width="120px"
      >
        <el-form-item label="Название" prop="name">
          <el-input
            v-model="categoryForm.name"
            @input="handleNameInput"
          ></el-input>
        </el-form-item>

        <el-form-item label="Slug" prop="slug">
          <el-input v-model="categoryForm.slug"></el-input>
        </el-form-item>

        <el-form-item label="Описание" prop="description">
          <el-input
            type="textarea"
            v-model="categoryForm.description"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Отмена</el-button>
        <el-button type="primary" :loading="saveLoading" @click="saveCategory"
          >Сохранить</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCategories,
  createCategory,
  updateCategory,
} from "@/api/categories";

export default {
  name: "Categories",
  data: () => ({
    categories: [],
    loading: false,
    filters: {
      total: 0,
      pages_count: 0,
      page: 1,
      limit: 10,
      query: null,
    },
    dialogVisible: false,
    saveLoading: false,
    categoryForm: {
      name: "",
      slug: "",
      description: "",
    },
    rules: {
      name: [
        {
          required: true,
          message: "Пожалуйста, введите название",
          trigger: "blur",
        },
        { min: 2, message: "Минимум 2 символа", trigger: "blur" },
      ],
      slug: [
        {
          required: true,
          message: "Пожалуйста, введите slug",
          trigger: "blur",
        },
      ],
    },
  }),
  computed: {
    page() {
      return this.$route.query.page;
    },
    query() {
      return this.$route.query.query;
    },
    isNewCategory() {
      return !this.categoryForm.id;
    },
  },
  async created() {
    if (this.page) {
      this.filters.page = parseInt(this.page, 10);
    }
    if (this.query) {
      this.filters.query = this.query;
    }
    await this.getCategories();
  },
  methods: {
    async getCategories() {
      this.loading = true;
      try {
        const result = await getCategories(this.filters);
        const { categories, pages_count, total } = result;
        this.categories = categories;
        this.filters.pages_count = pages_count;
        this.filters.total = total;
      } catch (error) {
        console.error("Ошибка при получении категорий:", error);
        this.$message.error("Не удалось загрузить категории");
      } finally {
        this.loading = false;
      }
    },

    editCategory(category) {
      if (category.id === "new") {
        this.categoryForm = {
          name: "",
          slug: "",
          description: "",
        };
      } else {
        this.categoryForm = { ...category };
      }
      this.dialogVisible = true;
    },

    handleDialogClose() {
      this.$refs.categoryForm?.resetFields();
      this.categoryForm = {
        name: "",
        slug: "",
        description: "",
      };
    },

    handleNameInput(value) {
      if (!this.categoryForm.slug) {
        this.categoryForm.slug = value
          .toLowerCase()
          .replace(/\s+/g, "-")
          .replace(/[^a-z0-9-]/g, "");
      }
    },

    async saveCategory() {
      try {
        await this.$refs.categoryForm.validate();
        this.saveLoading = true;

        if (this.isNewCategory) {
          await createCategory(this.categoryForm);
          this.$message.success("Категория успешно создана");
        } else {
          await updateCategory(this.categoryForm.id, this.categoryForm);
          this.$message.success("Категория успешно обновлена");
        }

        this.dialogVisible = false;
        await this.getCategories();
      } catch (error) {
        console.error("Ошибка при сохранении категории:", error);
        this.$message.error("Не удалось сохранить категорию");
      } finally {
        this.saveLoading = false;
      }
    },

    changeUrlSearchParams() {
      this.$router.push({
        query: {
          page: this.filters.page,
          query: this.filters.query,
        },
      });
    },

    async handleFiltersChange(param) {
      if (param !== "page") {
        this.filters.page = 1;
      }
      this.changeUrlSearchParams();
      await this.getCategories();
    },

    indexMethod(index) {
      return index + 1 + (this.filters.page - 1) * this.filters.limit;
    },
  },
};
</script>

<style lang="scss" scoped>
.categories {
  table {
    th {
      padding: 10px;
    }
    td {
      padding: 10px;
    }
  }
}
</style>
