import apiClient from "@/api/apiClient";

export const getCategories = async (filters) => {
  const result = await apiClient.get("/api/categories", {
    params: {
      ...filters,
    },
  });
  return result.data;
};

export const createCategory = async (payload) => {
  const result = await apiClient.post("/api/categories", payload);
  return result.data;
};

export const updateCategory = async (id, payload) => {
  const result = await apiClient.put(`/api/categories/${id}`, payload);
  return result.data;
};
